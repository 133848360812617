import "./App.css";
import { Footer } from "./Footer";
import { getBrowser } from "./getBrowser";
import { BodyLayout, PageHeader } from "./Layout";

let isSafari = getBrowser();

function App() {
  return (
    <div className={isSafari ? "App Safari" : "App"}>
      <header>
        <PageHeader />
      </header>
      <BodyLayout />
      <Footer />
    </div>
  );
}

export default App;
