import {
  projInfo,
  addProject,
  deleteProject,
  updateTechSummary,
  moveUpProject,
  moveDownProject,
  updateProjectInfo,
} from "./ProjectsReducer";

import { useDispatch, useSelector } from "react-redux";
import {
  ContainerEmpty,
  ContainerNested,
  ResizeInput,
  ResizeTextarea,
} from "./Containers";
import { Date } from "./Date";

export function ProjectContainer() {
  const projects = useSelector(projInfo);
  const dispatch = useDispatch();
  const addAction = () => dispatch(addProject());
  return (
    <div className="experience">
      <h3>Work Experience</h3>
      {projects && projects.length !== 0 ? (
        projects.map((project, i) => (
          <Project
            project={project}
            items={projects.length}
            key={project.id}
            id={i}
          />
        ))
      ) : (
        <ContainerEmpty addAction={addAction} />
      )}
    </div>
  );
}

function Project(props) {
  const dispatch = useDispatch();
  let data = {
    id: props.project.id,
    category: "",
    info: "",
  };

  const deleteAction = (id) => dispatch(deleteProject(id));
  const addAction = () => dispatch(addProject());
  const moveUpAction = (id) => dispatch(moveUpProject(id));
  const moveDownAction = (id) => dispatch(moveDownProject(id));

  const onUpdatePosition = (e) => {
    data.info = e.target.value;
    data.category = "position";
    dispatch(updateProjectInfo(data));
  };

  const onUpdateCompanyName = (e) => {
    data.info = e.target.value;
    data.category = "name";
    dispatch(updateProjectInfo(data));
  };

  const onUpdateProjDesc = (e) => {
    data.info = e.target.value;
    data.category = "summary";
    dispatch(updateProjectInfo(data));
  };

  const onUpdateProjResp = (e) => {
    data.info = e.target.value;
    data.category = "highlights";
    dispatch(updateProjectInfo(data));
  };

  const onUpdateProjTech = (e) => {
    data.info = e.target.value;
    data.category = "technologies";
    dispatch(updateProjectInfo(data));
  };

  const onFinishUpdateTech = (e) => {
    data.info = e.target.value;
    dispatch(updateTechSummary(data));
  };
  const onUpdateStartDate = (e) => {
    data.info = e.target.value;
    data.category = "startDate";
    dispatch(updateProjectInfo(data));
  };

  const onUpdateEndDate = (e) => {
    data.info = e.target.value;
    data.category = "endDate";
    dispatch(updateProjectInfo(data));
  };
  return (
    <ContainerNested
      deleteAction={deleteAction}
      addAction={addAction}
      item={props.project}
      items={props.items}
      id={props.id}
      moveUpAction={moveUpAction}
      moveDownAction={moveDownAction}
    >
      <ResizeInput
        id={props.project.id}
        value={props.project.name}
        onChangeHandler={onUpdateCompanyName}
        bold={true}
        maxLength="65"
      />
      <br />
      <ResizeInput
        id={props.project.id}
        value={props.project.position}
        onChangeHandler={onUpdatePosition}
        bold={true}
        maxLength="65"
      />
      <Date
        item={props.project}
        endDateCallback={onUpdateEndDate}
        startDateCallback={onUpdateStartDate}
      />
      <ResizeTextarea
        id={props.project.id}
        value={props.project.summary}
        onChangeHandler={onUpdateProjDesc}
      />
      <ResizeTextarea
        id={props.project.id}
        value={props.project.highlights}
        onChangeHandler={onUpdateProjResp}
      />
      <ResizeTextarea
        id={props.project.id}
        value={props.project.technologies}
        onChangeHandler={onUpdateProjTech}
        onBlurHandler={onFinishUpdateTech}
      />
    </ContainerNested>
  );
}
