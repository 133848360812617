import { useDispatch } from "react-redux";
import { importJson } from "./JsonReducer";
import { useSelector } from "react-redux";
import { projInfo, techInfo } from "./ProjectsReducer";
import { basicsInfo } from "./BasicsReducer";
import { langInfo } from "./LangsReducer";
import { eduInfo } from "./EducationReducer";
import { jsonInfo } from "./JsonReducer";

export function ImportJson() {
  const dispatch = useDispatch();

  const uploadJsonHandler = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      dispatch(importJson(JSON.parse(reader.result)));
    };

    file && reader.readAsText(file);
  };

  return (
    <div>
      <label htmlFor="file-upload" className="regular-button">
        Import JSON CV
      </label>
      <input
        id="file-upload"
        accept="json"
        type="file"
        onChange={(e) => uploadJsonHandler(e)}
      />
    </div>
  );
}

export function ExportJson() {
  const work = useSelector(projInfo);
  const skills = useSelector(techInfo);
  const languages = useSelector(langInfo);
  const education = useSelector(eduInfo);
  let jsonData = useSelector(jsonInfo);
  jsonData = { ...jsonData };
  const basics = useSelector(basicsInfo);
  const newBasics = { ...basics };
  newBasics.photoSrc = "";
  const data = {
    basics: newBasics,
    work,
    volunteer: jsonData.volunteer,
    education,
    awards: jsonData.awards,
    publications: jsonData.publications,
    skills,
    languages,
    interests: jsonData.interests,
    references: jsonData.references,
    projects: jsonData.projects,
  };
  const json = JSON.stringify(data, null, " ");
  const blob = new Blob([json], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const fileName =
    basics.name.replace(/\s/g, "") +
    "_" +
    basics.label.replace(/\s/g, "") +
    "_CV.json";

  return (
    <a href={url} className="regular-button" download={fileName}>
      Export JSON CV
    </a>
  );
}
