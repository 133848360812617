const initialState = {
  langIdCounter: 0,
  languages: [
    {
      id: 0,
      language: "English",
      fluency: "Advanced",
    },
  ],
};

export const langReducer = (state = initialState, action) => {
  let langsUpdated = [...state.languages];
  let id = action.payload ? action.payload.id : null;
  let index = langsUpdated.findIndex((lang) => lang.id === id);
  switch (action.type) {
    case "lang/updateLangInfo":
      const lang = langsUpdated[index];
      lang[action.payload.category] = action.payload.info;
      return { ...state, languages: langsUpdated };
    case "lang/addLang":
      const newLang = { ...initialState.languages[0] };
      newLang.id = state.langIdCounter + 1;
      langsUpdated.push(newLang);
      return {
        languages: langsUpdated,
        langIdCounter: state.langIdCounter + 1,
      };
    case "lang/deleteLang":
      index = state.languages.findIndex((lang) => lang.id === action.payload);
      langsUpdated.splice(index, 1);
      return { ...state, languages: langsUpdated };
    case "lang/moveUpLang":
      index = langsUpdated.findIndex((lang) => lang.id === action.payload);
      const tempLangBefore = langsUpdated[index - 1];
      langsUpdated[index - 1] = langsUpdated[index];
      langsUpdated[index] = tempLangBefore;
      return { ...state, languages: langsUpdated };
    case "lang/moveDownLang":
      index = langsUpdated.findIndex((lang) => lang.id === action.payload);
      const tempLangAfter = langsUpdated[index + 1];
      langsUpdated[index + 1] = langsUpdated[index];
      langsUpdated[index] = tempLangAfter;
      return { ...state, languages: langsUpdated };
    case "json/importJson":
      langsUpdated = action.payload.languages.map((lang, i) => {
        return {
          id: i,
          language: lang.language,
          fluency: lang.fluency,
        };
      });
      return {
        ...state,
        languages: langsUpdated,
        langIdCounter: action.payload.languages.length - 1,
      };
    default:
      return state;
  }
};

export function updateLangInfo(data) {
  return {
    type: "lang/updateLangInfo",
    payload: data,
  };
}

export function addLang() {
  return {
    type: "lang/addLang",
  };
}

export function deleteLang(id) {
  return {
    type: "lang/deleteLang",
    payload: id,
  };
}

export function moveUpLang(id) {
  return {
    type: "lang/moveUpLang",
    payload: id,
  };
}

export function moveDownLang(id) {
  return {
    type: "lang/moveDownLang",
    payload: id,
  };
}

export const langInfo = (state) => state.languages.languages;
