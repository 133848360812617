import { combineReducers, createStore } from "redux";
import { basicsReducer } from "./BasicsReducer";
import { eduReducer } from "./EducationReducer";
import { workReducer } from "./ProjectsReducer";
import { langReducer } from "./LangsReducer";
import {
  volunteerReducer,
  awardsReducer,
  publicationsReducer,
  interestsReducer,
  referencesReducer,
  projectsReducer,
} from "./JsonReducer";
import { loadState, saveState } from "./localStorage";
import _ from "underscore";

const persistedState = loadState();

const rootReducer = combineReducers({
  basics: basicsReducer,
  education: eduReducer,
  work: workReducer,
  languages: langReducer,
  volunteer: volunteerReducer,
  awards: awardsReducer,
  publications: publicationsReducer,
  interests: interestsReducer,
  references: referencesReducer,
  projects: projectsReducer,
});

export const store = createStore(rootReducer, persistedState);

store.subscribe(
  _.throttle(() => {
    saveState({
      basics: store.getState().basics,
      work: store.getState().work,
      education: store.getState().education,
      languages: store.getState().languages,
      volunteer: store.getState().volunteer,
      awards: store.getState().awards,
      publications: store.getState().publications,
      interests: store.getState().interests,
      references: store.getState().references,
      projects: store.getState().projects,
      skills: store.getState().skills,
    });
  }, 2000)
);
