const initialState = {
  eduIdCounter: 0,
  education: [
    {
      id: 0,
      institution: "University",
      area: "Computer Science",
      studyType: "Master’s of Science",
      startDate: "2000",
      endDate: "2000",
    },
  ],
};

export const eduReducer = (state = initialState, action) => {
  let eduUpdated = [...state.education];
  const id = action.payload ? action.payload.id : null;
  let index = state.education.findIndex((inst) => inst.id === id);
  switch (action.type) {
    case "edu/updateEduInfo":
      const edu = eduUpdated[index];
      edu[action.payload.category] = action.payload.info;
      return { ...state, education: eduUpdated };
    case "edu/addEdu":
      const newEdu = { ...initialState.education[0] };
      newEdu.id = state.eduIdCounter + 1;
      eduUpdated.push(newEdu);
      return { education: eduUpdated, eduIdCounter: state.eduIdCounter + 1 };
    case "edu/deleteEdu":
      index = state.education.findIndex((inst) => inst.id === action.payload);
      eduUpdated.splice(index, 1);
      return { ...state, education: eduUpdated };
    case "edu/moveUpEdu":
      index = eduUpdated.findIndex((inst) => inst.id === action.payload);
      const tempEduBefore = eduUpdated[index - 1];
      eduUpdated[index - 1] = eduUpdated[index];
      eduUpdated[index] = tempEduBefore;
      return { ...state, education: eduUpdated };
    case "edu/moveDownEdu":
      index = eduUpdated.findIndex((lang) => lang.id === action.payload);
      const tempEduAfter = eduUpdated[index + 1];
      eduUpdated[index + 1] = eduUpdated[index];
      eduUpdated[index] = tempEduAfter;
      return { ...state, education: eduUpdated };
    case "json/importJson":
      eduUpdated = action.payload.education.map((edu, i) => {
        return {
          id: i,
          institution: edu.institution,
          area: edu.area,
          startDate: edu.startDate,
          endDate: edu.endDate,
          studyType: edu.studyType,
        };
      });
      return {
        ...state,
        education: eduUpdated,
        eduIdCounter: action.payload.education.length - 1,
      };
    default:
      return state;
  }
};

export function updateEduInfo(data) {
  return {
    type: "edu/updateEduInfo",
    payload: data,
  };
}

export function addEdu() {
  return {
    type: "edu/addEdu",
  };
}

export function deleteEdu(id) {
  return {
    type: "edu/deleteEdu",
    payload: id,
  };
}

export function moveUpEdu(id) {
  return {
    type: "edu/moveUpEdu",
    payload: id,
  };
}

export function moveDownEdu(id) {
  return {
    type: "edu/moveDownEdu",
    payload: id,
  };
}

export const eduInfo = (state) => state.education.education;
