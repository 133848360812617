import { updateTech, techInfo } from "./ProjectsReducer";
import { useDispatch, useSelector } from "react-redux";
import { ResizeTextarea } from "./Containers";

export function Skills() {
  const dispatch = useDispatch();
  let skills = useSelector(techInfo);
  let skillSet = skills
    .map((item) => item.keywords)
    .flat()
    .join(", ");

  const onUpdateTech = (e) => {
    dispatch(updateTech(e.target.value));
  };
  return (
    <div className="technologies">
      <h3>Skills</h3>
      <ResizeTextarea value={skillSet} onChangeHandler={onUpdateTech} />
    </div>
  );
}
