import {
  updatePhone,
  updateEmail,
  updateCityLocation,
  updateUrl,
  contactsInfo,
} from "./BasicsReducer";
import { useDispatch, useSelector } from "react-redux";
import { MdEmail, MdLocalPhone, MdLocationPin } from "react-icons/md";
import { HiLink } from "react-icons/hi";
import { ResizeInput } from "./Containers";

export function Contacts() {
  const dispatch = useDispatch();
  const contacts = useSelector(contactsInfo);

  const onUpdatePhoneHandler = (e) => {
    dispatch(updatePhone(e.target.value));
  };

  const onUpdateEmailHandler = (e) => {
    dispatch(updateEmail(e.target.value));
  };

  const onUpdateCityLocationHandler = (e) => {
    dispatch(updateCityLocation(e.target.value));
  };

  const onUpdateUrlHandler = (e) => {
    dispatch(updateUrl(e.target.value));
  };

  return (
    <div className="contacts">
      <svg width="0" height="0">
        <linearGradient id="gradient-pink" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#ff9e7d" offset="0%" />
          <stop stopColor="#ff698e" offset="100%" />
        </linearGradient>
      </svg>
      <svg width="0" height="0">
        <linearGradient id="gradient-blue" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#158ec5" offset="0%" />
          <stop stopColor="#8f69ff" offset="100%" />
        </linearGradient>
      </svg>
      <div className="contacts-container">
        <div>
          <MdLocalPhone size={20} />

          <ResizeInput
            value={contacts.phone}
            maxLength="20"
            onChangeHandler={onUpdatePhoneHandler}
            bald={false}
          />
        </div>
        <div>
          <MdEmail size={20} style={{ fill: "url(#gradient-blue)" }} />
          <ResizeInput
            value={contacts.email}
            maxLength="30"
            onChangeHandler={onUpdateEmailHandler}
            bald={false}
          />
        </div>
        <div>
          <MdLocationPin size={20} style={{ fill: "url(#gradient-pink)" }} />
          <ResizeInput
            value={contacts.location.city}
            maxLength="30"
            onChangeHandler={onUpdateCityLocationHandler}
            bald={false}
          />
          {/* <input 
                        className="small-input"
                        type="text"
                        value={contacts.location.countryCode}
                        size={contacts.location.countryCode.length}
                        maxLength="15"
                        onChange={(e) => onUpdateCountryLocationHandler(e.target.value)}
                    /> */}
        </div>
        <div>
          <HiLink size={20} />
          <ResizeInput
            value={contacts.url}
            maxLength="30"
            onChangeHandler={onUpdateUrlHandler}
            bald={false}
          />
        </div>
      </div>
    </div>
  );
}
