import { MdAddAPhoto } from "react-icons/md";
import { updatePhoto, photoInfo } from "./BasicsReducer";
import { useDispatch, useSelector } from "react-redux";

export function Photo() {
  const photoSrc = useSelector(photoInfo);
  const dispatch = useDispatch();

  const uploadImgHandler = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      dispatch(updatePhoto(reader.result));
    };

    file && reader.readAsDataURL(file);
  };

  return (
    <div className="photo">
      <svg width="0" height="0">
        <linearGradient
          id="gradient-purple"
          x1="100%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop stopColor="#ff9e7d" offset="0%" />
          <stop stopColor="#8f69ff" offset="100%" />
        </linearGradient>
      </svg>
      <div className="user-pic-container">
        <label htmlFor="file-input">
          {photoSrc ? (
            <img src={photoSrc} className="user-pic" alt="User Pic" />
          ) : (
            <MdAddAPhoto
              className="no-print"
              size={32}
              style={{ fill: "url(#gradient-purple)" }}
            />
          )}
        </label>
        <input
          id="file-input"
          type="file"
          accept="image/png/jpg/jpeg"
          onChange={(e) => uploadImgHandler(e)}
        />
      </div>
    </div>
  );
}
