import { ResizeInput } from "./Containers";

export function Date(props) {
  return (
    <div className="date-container">
      <ResizeInput
        id={props.item.id}
        bold={false}
        value={props.item.startDate}
        onChangeHandler={props.startDateCallback}
        maxLength="30"
      />
      <span> – </span>
      <ResizeInput
        id={props.item.id}
        bold={false}
        value={props.item.endDate}
        onChangeHandler={props.endDateCallback}
        maxLength="30"
      />
    </div>
  );
}
