import { useState } from "react";

export function DeleteButton(props) {
  const deleteHandler = (e) => {
    const id = parseInt(e.target.parentElement.getAttribute("containerid"));
    props.action(id);
  };
  return (
    <span
      className="delete-button"
      onClick={(e) => deleteHandler(e)}
      title="Delete"
      style={props.toggle}
    />
  );
}

export function AddButton(props) {
  const addHandler = () => {
    props.action();
  };
  return (
    <span
      className="add-button"
      onClick={() => addHandler()}
      title="Add"
      style={props.toggle}
    />
  );
}

export function UpButton(props) {
  const moveUp = (e) => {
    const id = parseInt(e.target.parentElement.getAttribute("containerid"));
    props.action(id);
  };
  return (
    <span
      className="up-button"
      onClick={(e) => moveUp(e)}
      title="Move up"
      style={props.toggle}
    />
  );
}

export function DownButton(props) {
  const moveDown = (e) => {
    const id = parseInt(e.target.parentElement.getAttribute("containerid"));
    props.action(id);
  };
  return (
    <span
      className="down-button"
      onClick={(e) => moveDown(e)}
      title="Move down"
      style={props.toggle}
    />
  );
}

export function Highlight() {
  const [title, setTitle] = useState("Show what I can edit");
  const highlightEditingFields = () => {
    setTitle(
      title === "Show what I can edit"
        ? "Hide what I can edit"
        : "Show what I can edit"
    );
    document.querySelectorAll("input[type='text']").forEach((node) => {
      node.classList.toggle("outline");
    });
    document.querySelectorAll("textarea").forEach((node) => {
      node.classList.toggle("outline");
    });
  };
  return (
    <div>
      <span
        className="regular-button"
        onClick={() => highlightEditingFields()}
        title={title}
      >
        {title}
      </span>
    </div>
  );
}

export function Donate() {
  return (
    <div>
      <form
        method="POST"
        acceptCharset="utf-8"
        action="https://www.liqpay.ua/api/3/checkout"
      >
        <input
          type="hidden"
          name="data"
          value="eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXlkb25hdGUiLCJhbW91bnQiOiIzNSIsImN1cnJlbmN5IjoiVUFIIiwiZGVzY3JpcHRpb24iOiJTdXBwb3J0IE15IENvb2wgQ1YgcHJvamVjdCIsInB1YmxpY19rZXkiOiJpMzg0NTg0NjcyOTEiLCJsYW5ndWFnZSI6ImVuIiwic2VydmVyX3VybCI6Imh0dHBzOi8vbXljb29sY3YuY29tLyIsInJlc3VsdF91cmwiOiJodHRwczovL215Y29vbGN2LmNvbS8ifQ=="
        />
        <input
          type="hidden"
          name="signature"
          value="CZCohD0IwP9S04kqeyUUCWMa5fY="
        />
        <button className="donate-button">
          <span title="Donate">Buy me a coffee</span>
        </button>
      </form>
    </div>
  );
}
