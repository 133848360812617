import {
  langInfo,
  deleteLang,
  addLang,
  moveUpLang,
  moveDownLang,
  updateLangInfo,
} from "./LangsReducer";
import { ContainerNested, ContainerEmpty, ResizeInput } from "./Containers";
import { useDispatch, useSelector } from "react-redux";

export function LangContainer() {
  const langs = useSelector(langInfo);
  const dispatch = useDispatch();
  const addAction = () => dispatch(addLang());

  return (
    <div className="languages">
      <h3> Languages </h3>
      <div className="flex-row-container">
        {langs.length !== 0 && langs ? (
          langs.map((item, i) => <Lang lang={item} key={i} items={langs} />)
        ) : (
          <ContainerEmpty addAction={addAction} />
        )}
      </div>
    </div>
  );
}

function Lang(props) {
  let data = {
    id: props.lang.id,
    category: "",
    info: "",
  };

  const dispatch = useDispatch();

  const deleteAction = (id) => dispatch(deleteLang(id));
  const addAction = () => dispatch(addLang());
  const moveUpAction = (id) => dispatch(moveUpLang(id));
  const moveDownAction = (id) => dispatch(moveDownLang(id));

  const onUpdateLang = (e) => {
    data.info = e.target.value;
    data.category = "language";
    dispatch(updateLangInfo(data));
  };

  const onUpdateFluency = (e) => {
    data.info = e.target.value;
    data.category = "fluency";
    dispatch(updateLangInfo(data));
  };

  return (
    <ContainerNested
      deleteAction={deleteAction}
      addAction={addAction}
      item={props.lang}
      items={props.items}
      moveUpAction={moveUpAction}
      moveDownAction={moveDownAction}
    >
      <ResizeInput
        id={props.lang.id}
        value={props.lang.language}
        onChangeHandler={onUpdateLang}
        bold={true}
        maxLength="15"
      />
      <span> – </span>
      <ResizeInput
        id={props.lang.id}
        value={props.lang.fluency}
        onChangeHandler={onUpdateFluency}
        bold={false}
        maxLength="15"
      />
    </ContainerNested>
  );
}
