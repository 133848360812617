import {
  eduInfo,
  deleteEdu,
  addEdu,
  moveUpEdu,
  moveDownEdu,
  updateEduInfo,
} from "./EducationReducer";
import { ContainerNested, ContainerEmpty, ResizeInput } from "./Containers";
import { useDispatch, useSelector } from "react-redux";
import { Date } from "./Date";

export function EducationContainer() {
  const education = useSelector(eduInfo);
  const dispatch = useDispatch();
  const addAction = () => dispatch(addEdu());

  return (
    <div className="education">
      <h3>Education</h3>
      {education.length !== 0 && education ? (
        education.map((item, i) => (
          <Education edu={item} key={i} items={education.length} id={i} />
        ))
      ) : (
        <ContainerEmpty addAction={addAction} />
      )}
    </div>
  );
}

function Education(props) {
  let data = {
    id: props.edu.id,
    category: "",
    info: "",
  };
  const dispatch = useDispatch();

  const deleteAction = (id) => dispatch(deleteEdu(id));
  const addAction = () => dispatch(addEdu());
  const moveUpAction = (id) => dispatch(moveUpEdu(id));
  const moveDownAction = (id) => dispatch(moveDownEdu(id));

  const onUpdateEduInstitute = (e) => {
    data.info = e.target.value;
    data.category = "institution";
    dispatch(updateEduInfo(data));
  };

  const onUpdateEduStudyType = (e) => {
    data.info = e.target.value;
    data.category = "studyType";
    dispatch(updateEduInfo(data));
  };

  const onUpdateEduArea = (e) => {
    data.info = e.target.value;
    data.category = "area";
    dispatch(updateEduInfo(data));
  };

  const onUpdateStartDate = (e) => {
    data.info = e.target.value;
    data.category = "startDate";
    dispatch(updateEduInfo(data));
  };

  const onUpdateEndDate = (e) => {
    data.info = e.target.value;
    data.category = "endDate";
    dispatch(updateEduInfo(data));
  };

  return (
    <ContainerNested
      deleteAction={deleteAction}
      addAction={addAction}
      item={props.edu}
      items={props.items}
      id={props.id}
      moveUpAction={moveUpAction}
      moveDownAction={moveDownAction}
    >
      <ResizeInput
        id={props.edu.id}
        value={props.edu.institution}
        onChangeHandler={onUpdateEduInstitute}
        bold={true}
        maxLength="65"
      />
      <Date
        item={props.edu}
        endDateCallback={onUpdateEndDate}
        startDateCallback={onUpdateStartDate}
      />
      <ResizeInput
        id={props.edu.id}
        value={props.edu.studyType}
        onChangeHandler={onUpdateEduStudyType}
        bold={false}
        maxLength="65"
      />
      <br />
      <ResizeInput
        id={props.edu.id}
        value={props.edu.area}
        onChangeHandler={onUpdateEduArea}
        bold={false}
        maxLength="65"
      />
    </ContainerNested>
  );
}
