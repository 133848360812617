export const loadState = () => {
  try {
    //localStorage.clear("MyCoolCVState");
    const serialisedState = localStorage.getItem("MyCoolCVState");
    if (serialisedState === null) {
      return undefined;
    }
    return JSON.parse(serialisedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("MyCoolCVState", serialisedState);
  } catch (err) {
    //ignore the error
  }
};
