import { updateSummary, summaryInfo } from "./BasicsReducer";
import { useDispatch, useSelector } from "react-redux";
import { ResizeTextarea } from "./Containers";

export function Summary() {
  const dispatch = useDispatch();
  const summary = useSelector(summaryInfo);

  const onUpdateSummaryHandler = (e) => {
    dispatch(updateSummary(e.target.value));
  };

  return (
    <div className="summary">
      <h3>Summary</h3>
      <ResizeTextarea
        value={summary}
        onChangeHandler={onUpdateSummaryHandler}
      />
    </div>
  );
}
