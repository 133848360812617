import { useState, useRef, useEffect } from "react";
import { DeleteButton, AddButton, UpButton, DownButton } from "./Buttons";
import AutosizeInput from "react-input-autosize";

export function ContainerNested(props) {
  const [toggleButtons, setToggleButtons] = useState({ display: "none" });
  return (
    <div
      className="nested-container"
      containerid={props.item.id}
      onMouseEnter={() => setToggleButtons({ display: "block" })}
      onMouseLeave={() => setToggleButtons({ display: "none" })}
    >
      <DeleteButton toggle={toggleButtons} action={props.deleteAction} />
      {props.items > 1 && props.id !== 0 ? (
        <UpButton toggle={toggleButtons} action={props.moveUpAction} />
      ) : (
        <span></span>
      )}
      {props.items > 1 && props.id !== props.items - 1 ? (
        <DownButton toggle={toggleButtons} action={props.moveDownAction} />
      ) : (
        <span></span>
      )}
      <AddButton toggle={toggleButtons} action={props.addAction} />
      {props.children}
    </div>
  );
}

export function ContainerEmpty(props) {
  const [toggleButtons, setToggleButtons] = useState({ display: "none" });
  return (
    <div
      className="empty-container"
      onMouseEnter={() => setToggleButtons({ display: "block" })}
      onMouseLeave={() => setToggleButtons({ display: "none" })}
    >
      <AddButton toggle={toggleButtons} action={props.addAction} />
    </div>
  );
}

export function ResizeInput(props) {
  return (
    <div
      className="autosize-input"
      containerid={props.id}
      data-value={props.value}
    >
      <AutosizeInput
        name="form-field-name"
        maxLength={props.maxLength}
        value={props.value}
        type="text"
        inputStyle={{
          fontSize: "inherit",
          fontWeight: props.bold ? "600" : "400",
        }}
        onChange={(e) => props.onChangeHandler(e)}
      />
    </div>
  );
}

export function ResizeTextarea(props) {
  let textRef = useRef();

  useEffect(() => {
    textRef.current.parentNode.dataset.value = props.value;
  }, []);

  return (
    <div
      className="input-sizer text"
      containerid={props.id}
      data-value={props.value}
    >
      <textarea
        type="text"
        value={props.value}
        onChange={(e) => props.onChangeHandler(e)}
        onBlur={props.onBlurHandler ? (e) => props.onBlurHandler(e) : null}
        ref={textRef}
      />
    </div>
  );
}
