export function ExportPDF() {
  const exportPDF = () => {
    window.print();
  };
  return (
    <span className="regular-button" onClick={exportPDF}>
      Export PDF/Print
    </span>
  );
}
