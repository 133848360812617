import { updateName, nameInfo, updateTitle, titleInfo } from "./BasicsReducer";
import { useDispatch, useSelector } from "react-redux";
import { ResizeInput } from "./Containers";

export function Name() {
  const dispatch = useDispatch();
  const name = useSelector(nameInfo);
  const title = useSelector(titleInfo);

  const onUpdateNameHandler = (e) => {
    dispatch(updateName(e.target.value));
  };

  const onUpdateTitleHandler = (e) => {
    dispatch(updateTitle(e.target.value));
  };

  return (
    <div className="name">
      <ResizeInput
        value={name}
        onChangeHandler={onUpdateNameHandler}
        bold={true}
        maxLength="60"
      />
      <br />
      <ResizeInput
        value={title}
        onChangeHandler={onUpdateTitleHandler}
        bold={true}
        maxLength="60"
      />
    </div>
  );
}
