let randomSummary = () => {
  let arr = [
    "Highly-skilled and proactive Web Engineer who can drive things on his own. Implemented complex web applications and led a team of 6 persons. Strong knowledge of algorithms and data structures. Proven experience with cutting-edge technologies. ",
    "Fullstack Developer with more than five years of solid hands-on experience in designing & developing front-end and back-end for web-based applications. Experience with creating and consuming RESTful APIs. Strong Computer Science fundamentals, object-oriented design skills, and producing well-engineered design (SOLID, design patterns)",
    "Frontend Developer with excellent knowledge of React.js, Redux, Typescript. Broad understanding of general web applications development, architectures, trends, and emerging technologies. Able to work in a team environment, problem-solving attitude, and willingness to learn continuously.",
    "Software Engineer with more than two years of experience in Front-End development. Have strong knowledge of React and its ecosystem (Redux, Saga, Router). Proficient in JavaScript/HTML/CSS. Strong communication skills, great team player.",
    "Have more than three years of experience in Front-End Development. Strong knowledge of React and Typescript. Proficient understanding of web markup, including HTML5, CSS3. Solid understanding of CSS pre-processing, such as SASS. Strong knowledge of JavaScript (ES6). Proficient understanding of cross-browser compatibility issues and ways to work around them. Self-motivated team player. ",
    "Dedicated Web Developer with five years of experience in website design. Strong creative and analytical skills. Team player with an eye for detail. I have proven hands-on experience with React/Redux libraries.",
    "Experienced developer with expertise in developing large-scale projects and rich web applications. Experienced in HTML5/CSS3 and ES6. Goal-oriented and driven team player. Constantly learn new tools and languages to stay current with industry trends.",
  ];
  const item = Math.floor(Math.random() * arr.length);
  return arr[item];
};

const initialState = {
  name: "Name and Surname",
  label: "Title",
  photoSrc: "",
  summary: randomSummary(),
  email: "Email",
  phone: "Phone",
  location: {
    address: "",
    postalCode: "",
    city: "City",
    countryCode: "Country Code",
    region: "",
  },
  url: "https://",
  profiles: [],
};
export const basicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        name: action.payload.basics.name,
        label: action.payload.basics.label,
        photoSrc: action.payload.basics.image,
        summary: action.payload.basics.summary,
        phone: action.payload.basics.phone,
        email: action.payload.basics.email,
        location: action.payload.basics.location,
        url: action.payload.basics.url,
        profiles: action.payload.basics.profiles,
      };
    case "name/updateName":
      return { ...state, name: action.payload };
    case "title/updateTitle":
      return { ...state, label: action.payload };
    case "summary/updateSummary":
      return { ...state, summary: action.payload };
    case "photo/updatePhoto":
      return { ...state, photoSrc: action.payload };
    case "phone/updatePhone":
      return { ...state, phone: action.payload };
    case "email/updateEmail":
      return { ...state, email: action.payload };
    case "location/updateCityLocation":
      return {
        ...state,
        location: { ...state.location, city: action.payload },
      };
    case "location/updateCountryLocation":
      return {
        ...state,
        location: { ...state.location, countryCode: action.payload },
      };
    case "url/updateUrl":
      return { ...state, url: action.payload };
    default:
      return state;
  }
};

export function updateName(text) {
  return {
    type: "name/updateName",
    payload: text,
  };
}

export function updateTitle(text) {
  return {
    type: "title/updateTitle",
    payload: text,
  };
}

export function updateSummary(text) {
  return {
    type: "summary/updateSummary",
    payload: text,
  };
}

export function updatePhone(text) {
  return {
    type: "phone/updatePhone",
    payload: text,
  };
}

export function updateEmail(text) {
  return {
    type: "email/updateEmail",
    payload: text,
  };
}

export function updateCityLocation(text) {
  return {
    type: "location/updateCityLocation",
    payload: text,
  };
}

export function updateCountryLocation(text) {
  return {
    type: "location/updateCountryLocation",
    payload: text,
  };
}

export function updateUrl(text) {
  return {
    type: "url/updateUrl",
    payload: text,
  };
}

export function updatePhoto(src) {
  return {
    type: "photo/updatePhoto",
    payload: src,
  };
}

export const nameInfo = (state) => state.basics.name;

export const titleInfo = (state) => state.basics.label;

export const photoInfo = (state) => state.basics.photoSrc;

export const summaryInfo = (state) => state.basics.summary;

export const contactsInfo = (state) => {
  return {
    phone: state.basics.phone,
    email: state.basics.email,
    location: state.basics.location,
    url: state.basics.url,
  };
};

export const basicsInfo = (state) => state.basics;
