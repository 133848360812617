import { Name } from "./BasicsName";
import { Summary } from "./BasicsSummary";
import { Contacts } from "./BasicsContacts";
import { ProjectContainer } from "./Projects";
import { EducationContainer } from "./Education";
import { LangContainer } from "./Langs";
import { Skills } from "./Skills";
import { Photo } from "./BasicsPhoto";
import { UserPanel } from "./UserPanel";

export function PageHeader() {
  return (
    <div className="no-print">
      <h2>Hello! Let's create your cool CV</h2>
    </div>
  );
}

export function BodyLayout() {
  return (
    <div className="wrapper">
      <UserPanel />
      <div className="resume-body">
        <Photo />
        <div className="basics-container">
          <Name />
          <Contacts />
        </div>
        <div className="aside-left">
          <Skills />
          <LangContainer />
        </div>
        <div className="aside-right">
          <Summary />
          <ProjectContainer />
          <EducationContainer />
        </div>
      </div>
    </div>
  );
}
