const initialState = {
  skills: [
    {
      name: "All skills",
      level: "Master",
      keywords: ["React", "Redux", "TypeScript"],
    },
  ],
  projectIdCounter: 0,
  projects: [
    {
      id: 0,
      name: "Best Company Name",
      url: "",
      position: "Your Position",
      startDate: "2000",
      endDate: "2000",
      summary: "Project Description",
      highlights: "Highlights of your work",
      technologies: "Technologies",
    },
  ],
};
export const workReducer = (state = initialState, action) => {
  let projectsUpdated = [...state.projects];
  let id = action.payload ? action.payload.id : null;
  let index = projectsUpdated.findIndex((project) => project.id === id);
  let newSkills = [...state.skills];
  let indexSkills = newSkills.findIndex((item) => item.name === "All skills");

  switch (action.type) {
    case "projects/updateInfo":
      const project = projectsUpdated[index];
      project[action.payload.category] = action.payload.info;
      return { ...state, projects: projectsUpdated };
    case "tech/updateTech":
      console.log(indexSkills, newSkills);
      indexSkills !== -1
        ? (newSkills[indexSkills].keywords = action.payload.split(", "))
        : newSkills.push({
            name: "All skills",
            level: "Master",
            keywords: action.payload.split(", "),
          });
      return { ...state, skills: newSkills };
    case "projects/addProject":
      const newProject = { ...initialState.projects[0] };
      newProject.id = state.projectIdCounter + 1;
      projectsUpdated.push(newProject);
      return {
        ...state,
        projects: projectsUpdated,
        projectIdCounter: state.projectIdCounter + 1,
      };
    case "projects/deleteProject":
      index = projectsUpdated.findIndex(
        (project) => project.id === action.payload
      );
      projectsUpdated.splice(index, 1);
      return { ...state, projects: projectsUpdated };
    case "projects/moveUpProject":
      index = projectsUpdated.findIndex(
        (project) => project.id === action.payload
      );
      const tempProjectBefore = projectsUpdated[index - 1];
      projectsUpdated[index - 1] = projectsUpdated[index];
      projectsUpdated[index] = tempProjectBefore;
      return { ...state, projects: projectsUpdated };
    case "projects/moveDownProject":
      index = projectsUpdated.findIndex(
        (project) => project.id === action.payload
      );
      const tempProjectAfter = projectsUpdated[index + 1];
      projectsUpdated[index + 1] = projectsUpdated[index];
      projectsUpdated[index] = tempProjectAfter;
      return { ...state, projects: projectsUpdated };
    case "tech/updateTechSummary":
      const newTech = projectsUpdated[index].technologies.split(", ");
      let oldSkills = state.skills.map((item) => item.keywords).flat();
      let skillSet = [...oldSkills, ...newTech];
      skillSet = [...new Set(skillSet)];
      indexSkills !== -1
        ? (newSkills[indexSkills].keywords = skillSet)
        : newSkills.push({
            name: "All skills",
            level: "Master",
            keywords: skillSet,
          });
      return { ...state, skills: newSkills };
    case "json/importJson":
      const projectsJson = action.payload.work.map((project, i) => {
        return {
          id: i,
          name: project.name,
          url: project.url,
          position: project.position,
          startDate: project.startDate,
          endDate: project.endDate,
          summary: project.summary,
          highlights: project.highlights,
          technologies: project.technologies
            ? project.technologies
            : "Project Technologies",
        };
      });
      return {
        ...state,
        projects: projectsJson,
        projectIdCounter: action.payload.work.length - 1,
        skills: action.payload.skills,
      };
    default:
      return state;
  }
};

export function updateProjectInfo(data) {
  return {
    type: "projects/updateInfo",
    payload: data,
  };
}

export function addProject() {
  return {
    type: "projects/addProject",
  };
}

export function deleteProject(id) {
  return {
    type: "projects/deleteProject",
    payload: id,
  };
}

export function moveUpProject(id) {
  return {
    type: "projects/moveUpProject",
    payload: id,
  };
}

export function moveDownProject(id) {
  return {
    type: "projects/moveDownProject",
    payload: id,
  };
}

export function updateTechSummary(data) {
  return {
    type: "tech/updateTechSummary",
    payload: data,
  };
}

export function updateTech(text) {
  return {
    type: "tech/updateTech",
    payload: text,
  };
}

export const techInfo = (state) => state.work.skills;

export const projInfo = (state) => state.work.projects;
