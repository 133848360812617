import { useState } from "react";
import { Popup } from "./Popup";

const textAbout = `​​The My Cool CV is a free user-friendly serverless WYSIWYG SPA for creating
resumes. What does that mean? All data is stored only in a browser,
without sending it to an external server. The main goal of this app is to help you create
clean and simple CV and store it in PDF/JSON formats.`;

const textBugReport = `Found a bug or have a good suggestion? Please send me details – jujuvorona@gmail.com :)`;

export function Footer() {
  const [togglePopup, setTogglePopup] = useState(false);
  const onClickHandler = () => {
    setTogglePopup(!togglePopup);
  };
  return (
    <div className="footer no-print">
      {togglePopup ? (
        <Popup
          doitButton={false}
          closeAction={() => onClickHandler()}
          text={textAbout}
          additionalText={textBugReport}
        />
      ) : (
        <> </>
      )}
      <img className="logo" src="../logo192.png" />
      <span onClick={() => onClickHandler()}>About</span>
    </div>
  );
}
