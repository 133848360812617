export function Popup(props) {
  return (
    <div className="popup">
      <p>{props.text}</p>
      <p>{props.additionalText}</p>
      <div className="popup-buttons-container">
        <span onClick={props.closeAction}>Close</span>
        {props.doitButton ? (
          <span onClick={props.doitAction}>Do it!</span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
