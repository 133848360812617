import { ImportJson, ExportJson } from "./JsonHandlers";
import { Highlight, Donate } from "./Buttons";
import { ExportPDF } from "./PDFHandler";
import { getBrowser } from "./getBrowser";

let isSafari = getBrowser();

export function UserPanel() {
  return (
    <div className="user-panel no-print">
      <Highlight />
      <ImportJson />
      <ExportJson />
      {
        //isSafari ? <></> : <ExportPDF />
      }
      <ExportPDF />
      <Donate />
    </div>
  );
}
