export const volunteerReducer = (state = [], action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        volunteer: action.payload.volunteer,
      };
    default:
      return state;
  }
};

export const awardsReducer = (state = [], action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        awards: action.payload.awards,
      };
    default:
      return state;
  }
};

export const publicationsReducer = (state = [], action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        publications: action.payload.publications,
      };
    default:
      return state;
  }
};

export const interestsReducer = (state = [], action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        interests: action.payload.interests,
      };
    default:
      return state;
  }
};

export const referencesReducer = (state = [], action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        references: action.payload.references,
      };
    default:
      return state;
  }
};

export const projectsReducer = (state = [], action) => {
  switch (action.type) {
    case "json/importJson":
      return {
        ...state,
        projects: action.payload.projects,
      };
    default:
      return state;
  }
};

export function importJson(json) {
  return {
    type: "json/importJson",
    payload: json,
  };
}

export const jsonInfo = (state) => state.json;
